import { template as template_827794cc1ba048b28e37a9e99b5ddbd0 } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import { t } from 'ember-intl';
import ActionBar from '../ui/action-bar';
export default template_827794cc1ba048b28e37a9e99b5ddbd0(`
  <ActionBar>
    <:information>
      {{t "pages.sco-organization-participants.action-bar.information" count=@count}}
    </:information>
    <:actions>
      {{#if @hasGarIdentityProvider}}
        <PixButton @triggerAction={{@openResetPasswordModal}}>
          {{t "pages.sco-organization-participants.action-bar.reset-password-button"}}
        </PixButton>
      {{else}}
        <PixButton @triggerAction={{@openGenerateUsernamePasswordModal}}>
          {{t "pages.sco-organization-participants.action-bar.generate-username-password-button"}}
        </PixButton>
      {{/if}}
    </:actions>
  </ActionBar>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
