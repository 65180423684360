import { template as template_bc93525c343c4119a4e7a83509ca6ab1 } from "@ember/template-compiler";
import ParticipantsByMasteryPercentage from './participants-by-mastery-percentage';
import ParticipantsByStage from './participants-by-stage';
export default template_bc93525c343c4119a4e7a83509ca6ab1(`
  {{#if @campaign.hasStages}}
    <ParticipantsByStage
      @campaignId={{@campaign.id}}
      @onSelectStage={{@onSelectStage}}
      class="assessment-results__charts hide-on-mobile"
    />
  {{else}}
    <ParticipantsByMasteryPercentage @campaignId={{@campaign.id}} class="assessment-results__charts hide-on-mobile" />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
