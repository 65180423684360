import { template as template_89900fe3ca144523b3413f3d32973f5f } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_89900fe3ca144523b3413f3d32973f5f(`
  <p class="screen-reader-only">{{t "charts.participants-by-mastery-percentage.loader"}}</p>
  <div class="participants-by-mastery-percentage--loader placeholder-box" aria-hidden="true"></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
