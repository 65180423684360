import { template as template_bd11e910cb22422d8249339f20c1e7ee } from "@ember/template-compiler";
import { t } from 'ember-intl';
import capitalize from 'lodash/capitalize';
export default template_bd11e910cb22422d8249339f20c1e7ee(`
  <section class="panel empty-state">
    <img src="{{this.rootURL}}/images/empty-state-activity.svg" alt="" role="none" />

    <div class="empty-state__text">
      <p>{{t
          "pages.organization-learner.activity.empty-state"
          organizationLearnerFirstName=(capitalize @firstName)
          organizationLearnerLastName=(capitalize @lastName)
        }}</p>
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
