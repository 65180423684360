import { template as template_b06abe17f44542ae90e7802521491472 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import UploadButton from '../upload-button';
import ImportCard from './import-card';
export default template_b06abe17f44542ae90e7802521491472(`
  <ImportCard @cardTitle={{t "pages.organization-participants-import.actions.participants.title"}}>
    <:cardDetails>
      <p class="import-card__sub-title">
        {{t "pages.organization-participants-import.actions.participants.details"}}
      </p>

      <ul class="import-card__list">
        <li>{{t "pages.organization-participants-import.actions.participants.details-add"}}</li>
        <li>{{t "pages.organization-participants-import.actions.participants.details-update"}}</li>
        <li>{{t "pages.organization-participants-import.actions.participants.details-disable"}}</li>
      </ul>
    </:cardDetails>
    <:cardFooter>
      <UploadButton
        @id="students-file-upload"
        @size="small"
        @disabled={{@disabled}}
        @onChange={{@importHandler}}
        @supportedFormats={{@supportedFormats}}
      >
        {{t "pages.organization-participants-import.actions.participants.label"}}
      </UploadButton>
    </:cardFooter>
  </ImportCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
