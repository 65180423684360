import { template as template_8d7e14e1b33f48e887d9a6f2b71a8c5d } from "@ember/template-compiler";
import { t } from 'ember-intl';
import TooltipWithIcon from '../../ui/tooltip-with-icon';
export default template_8d7e14e1b33f48e887d9a6f2b71a8c5d(`
  <span class="evolution-header">

    {{t "pages.campaign-results.table.column.evolution"}}

    <TooltipWithIcon
      @iconName="help"
      @content={{@tooltipContent}}
      @ariaHiddenIcon={{true}}
      class="tooltip-with-icon-small"
    />
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
