import { template as template_5c8a4c7efddd46e386ab68b77ca8f836 } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { uniqueId } from '@ember/helper';
const tooltipId = uniqueId();
export default template_5c8a4c7efddd46e386ab68b77ca8f836(`
  <PixTooltip @id={{tooltipId}} @isInline={{true}}>
    <:triggerElement>

      <PixIcon
        @name={{@iconName}}
        @plainIcon={{true}}
        aria-label={{@content}}
        aria-describedby={{tooltipId}}
        @ariaHidden={{@ariaHiddenIcon}}
        ...attributes
      />

    </:triggerElement>

    <:tooltip>
      {{@content}}
    </:tooltip>

  </PixTooltip>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
