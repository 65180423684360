import { template as template_70b2fc62a84a4c8787f1d8d53ae77ada } from "@ember/template-compiler";
export default template_70b2fc62a84a4c8787f1d8d53ae77ada(`
  <section class="import-card">
    <h2 class="import-card__title">{{@cardTitle}}</h2>
    <div class="import-card__details">
      {{yield to="cardDetails"}}
    </div>

    <div class="import-card__footer">
      {{yield to="cardFooter"}}
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
