import { template as template_f559178aa2ed41b491bd4c4d738b1ab7 } from "@ember/template-compiler";
function setTitleClasses(spaceBetweenTools, centerTitle) {
    const classes = [
        'page-title__main'
    ];
    if (spaceBetweenTools) classes.push('page-title__main--stick');
    if (centerTitle) classes.push('page-title__main--center');
    return classes.join(' ');
}
export default template_f559178aa2ed41b491bd4c4d738b1ab7(`
  <header class="page-title" ...attributes>
    {{#if (has-block "breadcrumb")}}
      {{yield to="breadcrumb"}}
    {{/if}}
    <div class={{setTitleClasses @spaceBetweenTools @centerTitle}}>
      <h1 class="page-title__title">{{yield to="title"}}</h1>
      {{#if (has-block "tools")}}
        {{yield to="tools"}}
      {{/if}}
    </div>

    {{#if (has-block "notificationAlert")}}
      <div class="page-title__notification-alert">
        {{yield to="notificationAlert"}}
      </div>
    {{/if}}

    {{#if (has-block "subtitle")}}
      <div class="page-title__sub-title">
        {{yield to="subtitle"}}
      </div>
    {{/if}}

  </header>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
