import { template as template_bbfba19a3be346208433baa63cde225c } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
export default template_bbfba19a3be346208433baa63cde225c(`
  <nav class="mission-navigation__tabs" aria-label={{t "pages.missions.mission.tabs.aria-label"}}>
    <LinkTo @route="authenticated.missions.mission.activities" class="navbar-item">
      {{t "pages.missions.mission.tabs.activities"}}
    </LinkTo>
    <LinkTo @route="authenticated.missions.mission.results" class="navbar-item">
      {{t "pages.missions.mission.tabs.results"}}
    </LinkTo>
  </nav>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
