import { template as template_9eaba230c01841d68c4d8f3ba86c5dfa } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import getService from '../../helpers/get-service.js';
function shouldDisplayBanner(session) {
    const localeNotSupported = session?.data?.localeNotSupported;
    const localeNotSupportedBannerClosed = session?.data?.localeNotSupportedBannerClosed;
    return localeNotSupported && !localeNotSupportedBannerClosed;
}
export default template_9eaba230c01841d68c4d8f3ba86c5dfa(`
  {{#let (getService "service:session") as |session|}}
    {{#if (shouldDisplayBanner session)}}
      <PixBannerAlert
        @type="information"
        @canCloseBanner="true"
        @onCloseBannerTriggerAction={{fn session.updateDataAttribute "localeNotSupportedBannerClosed" true}}
      >
        {{t "banners.language-availability.message"}}
      </PixBannerAlert>
    {{/if}}
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
