import { template as template_8028c56c89bb4b99b0d5d6620ba6302c } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_8028c56c89bb4b99b0d5d6620ba6302c(`
  <p class="screen-reader-only">{{t "charts.participants-by-day.loader"}}</p>
  <div class="participants-by-day--loader placeholder-box" aria-hidden="true"></div>
  <ul class="participants-by-day__legend" aria-hidden="true">
    <li class="participants-by-day__legend--loader placeholder-box" aria-hidden="true"></li>
    <li class="participants-by-day__legend--loader placeholder-box" aria-hidden="true"></li>
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
