import { template as template_f050068d59a9440a9a1a4c12b5e59cae } from "@ember/template-compiler";
export default template_f050068d59a9440a9a1a4c12b5e59cae(`
  <section class="import-card">
    <h2 class="import-card__title">{{@cardTitle}}</h2>
    <div class="import-card__details">
      {{yield to="cardDetails"}}
    </div>

    <div class="import-card__footer">
      {{yield to="cardFooter"}}
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
