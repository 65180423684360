import { template as template_b94944c2cfbb44bcb05f496390b8fbae } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_b94944c2cfbb44bcb05f496390b8fbae(`
  <p class="screen-reader-only">{{t "charts.participants-by-stage.loader"}}</p>
  <div class="participants-by-status__loader" aria-hidden="true">
    <span class="participants-by-status__loader--chart placeholder-doughnut"></span>
    <span class="participants-by-status__loader--legend placeholder-box"></span>
    <span class="participants-by-status__loader--legend placeholder-box"></span>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
